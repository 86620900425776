import { Text } from '@dsx/react';
import { Footer } from '../Footer/Footer';
import styles from './FooterDssi.module.css';
import type { FooterLink, FooterLinkBlock, SocialLink } from '../Footer';
import logoSVG from './../../logo-dssi-stacked.svg';

import {
	DsxLinkedin,
	DsxTwitter,
	DsxYoutube,
	DsxFacebook,
} from '@dsx-react/icon';

const linkBlocks: FooterLinkBlock[] = [
	{
		category: '',
		links: [
			{
				text: 'Insights',
				url: '/insights',
				openInNewTab: false,
			},
			{
				text: 'Success Stories',
				url: '/case-studies',
				openInNewTab: false,
			},
			{
				text: 'Get in Touch',
				url: '/contact',
				openInNewTab: false,
			},
			{
				text: 'Sign in',
				url: 'https://www.directs.com/dssi/default.aspx',
				openInNewTab: true,
			},
		],
	},
	{
		category: '',
		links: [
			{
				text: 'Spend Optimization',
				url: '/solutions/spend-optimization',
				openInNewTab: false,
			},
			{
				text: 'Supply Chain Consulting',
				url: '/solutions/spend-optimization/supply-chain-consulting',
				openInNewTab: false,
			},
			{
				text: 'AI in Procurement',
				url: '/solutions/spend-optimization/ai-in-procurement',
				openInNewTab: false,
			},
			{
				text: 'Food Procurement',
				url: '/solutions/spend-optimization/food-procurement',
				openInNewTab: false,
			},
		],
	},
	{
		category: '',
		links: [
			{
				text: 'Procure to Pay',
				url: '/solutions/procure-to-pay',
				openInNewTab: false,
			},
			{
				text: 'AP Automation',
				url: '/solutions/procure-to-pay/ap-automation',
				openInNewTab: false,
			},
			{
				text: 'Inventory Management',
				url: '/solutions/procure-to-pay/inventory-management',
				openInNewTab: false,
			},
			{
				text: 'Supply Chain Management',
				url: '/solutions/procure-to-pay/supply-chain-management',
				openInNewTab: false,
			},
		],
	},
];

const policyLinks: FooterLink[] = [
	{
		text: 'Terms of Use',
		url: '/legal/terms-of-use',
		openInNewTab: false,
	},
	{
		text: 'Privacy Policy',
		url: '/legal/privacy-policy',
		openInNewTab: false,
	},
	{
		text: 'Cookie Policy',
		url: '/legal/cookie-policy',
		openInNewTab: false,
	},
	{
		text: 'California Collection Notice',
		url: '/legal/privacy-policy#california-collection-notice',
		openInNewTab: false,
	},
];

const socialLinks: SocialLink[] = [
	{
		url: 'https://www.facebook.com/directsupply',
		openInNewTab: true,
		className: 'icon-facebook',
		title: 'Facebook',
		iconType: DsxFacebook,
	},
	{
		url: 'https://twitter.com/_DirectSupply',
		openInNewTab: true,
		className: 'icon-twitter',
		title: 'X',
		iconType: DsxTwitter,
	},
	{
		url: 'http://www.linkedin.com/company/direct-supply',
		openInNewTab: true,
		className: 'icon-linkedin',
		title: 'LinkedIn',
		iconType: DsxLinkedin,
	},
	{
		url: 'http://www.youtube.com/c/directsupply',
		openInNewTab: true,
		className: 'icon-youtube',
		title: 'YouTube',
		iconType: DsxYoutube,
	},
];

const renderContactContent = () => (
	<span>
		<Text variant="body-small">
			Need help or have a question?
			<br />
			<a href="/contact/" className={styles['contact-link']}>
				<Text variant="body-small" color="color-blue-500" element="span">
					Contact us
				</Text>
			</a>
			.
		</Text>
	</span>
);

const renderLogo = () => (
	<img src={logoSVG} alt="DS DSSI Logo" className={styles['logo']} />
);

const FooterDssi = () => {
	const currentYear = new Date().getFullYear();
	const copyright = `© 2009 - ${currentYear} Direct Supply, Inc. All rights reserved.`;
	const contact = renderContactContent();
	const logo = renderLogo();

	return (
		<Footer
			contact={contact}
			copyright={copyright}
			linkBlocks={linkBlocks}
			logo={logo}
			policyLinks={policyLinks}
			socialLinks={socialLinks}
		/>
	);
};

export { FooterDssi };
